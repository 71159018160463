<template>
  <div class="vipMain">
    <div class="backHome" v-if="toolsShow == true">
      <span class="backIco" @click="back('prve')"></span>
      <span class="backHomeIco" @click="back('main')"></span>
      {{title}}
    </div>
    <div class="scrollBar">
      <div class="scrollMain">
        <span v-for="(giftL,i) in giftTypeJson" :class="[i==now?'giftActive':'']"
          @click="giftChangel(giftL,i)">{{ giftL.text }}</span>
      </div>
    </div>
    <div class="giftBoxs cls">
      <div class="giftbox" v-for="gift in giftJson">
        <span class="pic" :style="{ backgroundImage: 'url(' + gift.picPath + ')' }"></span>
        <span class="title">{{ gift.title }}</span>
        <span class="money">{{ gift.money }}{{currentLang.coin}}</span>
        <span class="postGift" @click="giveGift(gift)">{{currentLang.giveGift}}</span>
      </div>
    </div>
    <div class="myGiftTitle">{{currentLang.myGift}}</div>
    <div class="giftBoxs cls">
      <div class="giftbox" v-for="gift in receivedGiftJson">
        <span class="pic" :style="{ backgroundImage: 'url(' + gift.picPath + ')' }"
          @click="viewDetails(gift.sendGiftUser)"></span>
        <span class="title">{{ gift.title }}</span>
        <span class="money">{{ gift.money }}{{currentLang.coin}}</span>
        <!-- <span class="postGift">发送礼物</span> -->
      </div>
    </div>
  </div>
</template>

<script>
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from 'js-cookie';
  import {
    getQueryVariable
  } from "@/utils/request";
  export default {
    name: "",
    title: "", //页面标题
    props: {},
    data() {
      return {
        deviceType: "",
        toolsShow: true, //显示工具栏
        now: 0,
        giftTypeJson: [

        ],
        giftJson: [

        ],
        receivedGiftJson: [

        ],
        languageLib: {
          lang_1: {
            title: 'Gifts',
            giveGift: 'give gift',
            myGift: 'my gift',
            coin: 'coin'
          },
          lang_2: {
            title: '礼物列表',
            giveGift: '发送礼物',
            myGift: '我收到的礼物',
            coin: '金币'
          },
          lang_3: {
            title: '禮物列表',
            giveGift: '發送禮物',
            myGift: '我收到的禮物',
            coin: '金幣'
          }
        },
        currentLang: {

        }
      };
    },
    components: {},
    beforeMount() {
      const language_id = Cookies.get('language_id') || 2; //2022-3-9 按照客户要求默认改为简体字 //客户现在要求默认是繁体
      this.currentLang = this.languageLib['lang_2'];
      this.title = this.currentLang.title;
    },
    mounted() {
      var url = location.href;
      this.type = getQueryVariable(url,
        "type"); //是直接打开还是别的路径来的
      this.deviceType = getQueryVariable(url,
        "deviceType");
      this.userCode = decodeURI(getQueryVariable(url,
        "userCode")); //如果是送礼模式，那么这里存着要收礼物人的账号
      if (this.userCode == null) {
        this.userCode = ""
      }
      //送礼状态下，不显示会员自己收到的礼物
      if (this.userCode != "") {
        //title显示为谁在选礼物
        this.title = this.genTitle(this.userCode);
      } else {
        this.title = this.currentLang.title;
      }
      if (this.deviceType == "pc") {
        //如果是pc，那么就是电脑调用，不显示对应的顶部导航
        this.toolsShow = false;
      }
      if (this.type != null) {
        //给别人送礼模式，就不显示自己收到的礼物


      } else {
        //获取会员自己收到礼物的记录

      }
      this.getMyGift(); //
      this.getclassify(); //加载礼物分类 //代码里面默认获取第一个部类的商品


    },
    methods: {
      genTitle(userCode) {
        const language_id = Cookies.get('language_id') || 2; //2022-3-9 按照客户要求默认改为简体字 //客户现在要求默认是繁体
        if (this.userCode == null || this.userCode == 'null') {
          //null就是没在送礼中
          return "礼物列表";
        } else {
          if (language_id == 2) {
            return "您正在为" + this.userCode + "挑选礼物";
          }
          if (language_id == 3) {
            return "您正在為" + this.userCode + "挑選禮物";
          }
          return 'Your are choosing gift for ' + userCode;
        }
      },
      back(where) {
        console.log("================>")
        if (where == "main") {
          this.$router.push({
            name: "main",
          });
        }
        if (where == "prve") {
          this.$router.back()
        }
        this.$router.push(-1);
      },
      //点击 礼物类型，动态修改
      giftChangel(gt, i) {
        this.giftJson = [];
        this.now = i
        this.getGoodsByCateItem(gt.gTypeId);
        console.log(gt.gTypeId, i);
      },
      viewDetails(userCode) {
        this.$router.push({
          path: '/userDet',
          query: {
            'userCode': userCode
          }
        });
      },
      giveGift(item) {
        //给这个会员送礼物
        this.$router.push({
          path: '/giveGift',
          query: {
            'userCode': this.userCode, //送给谁  空就表示没选人，用户自己搜
            'giftCode': item.giftid,
            'deviceType': this.deviceType,
            'giftTitle': item.money,

          }
        });
      },
      // async getGiftList(type) {
      //   //获取分类和礼物列表
      //   //先获取分类

      //   //在获取礼物
      //   this.type = type; //顶部选项页的选中index
      //   var that = this;
      //   var uri = "";
      //   uri = this.$global.domain + "/tools/MallSelect.ashx";
      //   var param = {
      //     action: "getUserList2021",
      //     user: Cookies.get("kh"),
      //     sex: Cookies.get("sex"),
      //     type: type,
      //     sitenum: '18777777777',
      //     token: Cookies.get("token"), //验证用户合法性
      //   };
      //   var result = await RequestApi.post(uri, param);
      //   // console.log(result);
      //   if (result.status == "1") {
      //     that.memberList = result.data;
      //   } else {
      //     console.log("==================>用户没登录")
      //     this.$router.push("login");
      //   }
      // },
      //获取分类
      async getclassify() {
        var param = {
          action: "getClassification",
          sitenum: '18777777777',
          token: Cookies.get("token"), //验证用户合法性
        };
        var uri = this.$global.domain + "/tools/MallSelect.ashx";
        var _this = this;
        try {
          var result = await RequestApi.post(uri, param);

          if (result.status == 1) {
            var data = result.data;
            if (data.length > 0) {
              for (var i = 0; i < data.length; i++) {
                var id = '';
                var title = '';
                let sss = data[i].toString().split(',')
                id = sss[0];
                title = sss[1];
                var sData = {
                  gTypeId: id,
                  text: title
                };
                _this.giftTypeJson.push(sData);
                if (i == 0) {
                  //获取第一个部类的id，加载对应的数据
                  this.getGoodsByCateItem(id);
                }
              }
            }
          } else {
            Toast(result.msg);
          }
        } catch (error) {
          Toast(error);
        }
      },
      //获取商品
      async getGoodsByCateItem(cateid) {
        var param = {
          action: "ShopList",
          sitenum: '18777777777',
          blid: cateid,
          token: Cookies.get("token"),
        };
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx"
        var _this = this;
        try {
          var res = await RequestApi.post(uri, param);
          console.log("res2", res);
          if (res.status == 1) {
            var data = res.data;
            if (data.length > 0) {
              for (var i = 0; i < data.length; i++) {
                var sData = {
                  picPath: data[i].shopimg,
                  title: data[i].shopname,
                  money: data[i].shopprice,
                  giftid: data[i].spid,

                };
                _this.giftJson.push(sData);
              }
            }
          } else {
            Toast(res.rsg);
          }
        } catch (error) {
          console.log("error_getGoodsByCateItem", error);
        }
      },
      //获取我收到的礼物，没收到就空
      async getMyGift() {
        var param = {
          action: "getMyGift2021",
          sitenum: '18777777777',
          user: Cookies.get("kh"), //给礼物人账号
          token: Cookies.get("token"),
        };
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx"
        var _this = this;
        try {
          var res = await RequestApi.post(uri, param);
          console.log("===============>我收到的礼物", res);
          if (res.status == 1) {
            var data = res.data;
            if (data.length > 0) {
              for (var i = 0; i < data.length; i++) {
                var sData = {
                  picPath: data[i].shopimg,
                  title: data[i].sendGiftUser + " send " + data[i].shopname,
                  money: data[i].shopprice,
                  giftid: data[i].spid,
                  sendGiftUser: data[i].sendGiftUser, //赠送人
                  words: data[i].words, //赠言
                };
                _this.receivedGiftJson.push(sData);
              }
            }
          } else {
            Toast(res.rsg);
          }
        } catch (error) {
          console.log("error_getGoodsByCateItem", error);
        }
      },

    },
    computed: {},
    watch: {},
  };
</script>

<style scoped lang="less">
  .vipMain {
    min-height: 300px;
    padding-bottom: 120px;
    padding-top: 50px;
  }

  /*-------------------------------- */
  .scrollBar {
    width: 344px;
    position: relative;
    height: 34px;
    margin: 5px auto 30px auto;
    //   border: 1px red dashed;
    overflow: hidden;

    .scrollMain {
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;
      /*第二层设置  不换行  */
      overflow-y: auto;
      height: 34px;
    }

    span {
      -webkit-overflow-scrolling: touch;
      line-height: 28px;
      height: 28px;
      width: 134px;
      border-radius: 10px;
      background-color: #f4f9fa;
      color: #444;
      margin-right: 16px;
      text-align: center;
      display: inline;
      position: relative;

      display: inline-block;
    }

    .giftActive {
      background-color: #ff4d54;
      color: white;
    }

    .scrollMain::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 100%;
      /*高宽分别对应横竖滚动条的尺寸*/
      height: 2px;
    }

    .scrollMain::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(248, 137, 118, 0.425);
      background: #535353;
    }

    .scrollMain::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow: inset 0 0 5px rgba(194, 194, 194, 0.4);
      border-radius: 10px;
      background: #ededed;
    }
  }

  .cls:after {
    content: "020";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }

  .cls {
    /* 触发 hasLayout */
    zoom: 1;
  }

  .giftBoxs {
    width: 344px;
    position: relative;
    min-height: 34px;
    margin: 5px auto 30px auto;

    //   border: 1px red dashed;
    .giftbox {
      width: 90px;
      height: 160px;
      float: left;
      // border: 1px pink dashed;
      display: block;
      position: relative;
      margin-left: 20px;
      margin-bottom: 12px;

      span {
        position: relative;
        //   border: 1px #9cc6ec dashed;
        display: inline-block;
      }

      .pic {
        top: 0px;
        height: 90px;
        width: 90px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: convert;
        border-radius: 5px;
      }

      .title {
        top: -3px;
        line-height: 14px;
        height: 14px;
        width: 88px;
        text-align: center;
        font-size: 12px;
        color: #222;
        font-weight: 600;
      }

      .money {
        top: -3px;
        line-height: 12px;
        height: 12px;
        width: 88px;
        text-align: center;
        font-size: 12px;
        color: #838383;
      }

      .postGift {
        top: 0px;
        line-height: 28px;
        height: 26px;
        width: 88px;
        border-radius: 20px;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        font-size: 12px;
        color: #d23d5b;
        border: 1px #d23d5b solid;
      }
    }
  }

  .myGiftTitle {
    height: 34px;
    width: 375px;
    position: relative;
    line-height: 34px;
    text-align: left;
    margin: 0 auto;
    margin-top: 22px;
    background-color: white;
    text-align: left;
    font-size: 16px;
    text-indent: 15px;
    color: #222;
  }

  /*-------------------------------- */
  .backHome {
    height: 38px;
    width: 100%;
    line-height: 38px;
    position: fixed;
    background-color: white;
    box-shadow: 2px 2px 6px #bfb8b8;
    display: inline-block;
    top: 0px;
    text-align: center;
    font-size: 16px;
    color: #222;
    z-index: 999;

    span {
      position: absolute;
      // border: 1px red dashed;
    }

    .backIco {
      left: 15px;
      top: 10px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/back2.png");
    }

    .backHomeIco {
      left: 43px;
      top: 10px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/home.png");
    }
  }
</style>
